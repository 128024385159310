import React from 'react';
import PropTypes from 'prop-types';
import Helmet from '../project/Helmet';
import CategoryForm from './category/CategoryForm';
import locationHOC from '../../components/locationProvider/locationHOC';
import withDataHOC from '../../components/dataProvider/withDataHOC';
import NavigateButton from '../general/NavigateButton';
import {createFetchCategoryEdit} from '../../backend/apiCalls';
import {navigateToParametrized, getQueryParam} from '../../lib/url';
import {ROUTES, QUERY_PARAMS} from '../../constants/navigation';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Location} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class CategoryEditPage extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {[GLOBAL_DATA.RELOAD_DATA]: reload,  location} = this.props;
        const categoryId = getQueryParam(location, [QUERY_PARAMS.ID_PRODUCT_GROUP]);
        return <React.Fragment>
            <Helmet
                title={t`Úprava produktovej kategórie`}
            />
            <div className="p-3 full-size-height overflow-y-auto">
                <div className="mb-3 d-flex justify-content-between">
                    <div  style={{width: '20rem'}}>
                        <NavigateButton
                            to={ROUTES.HOME}
                            queryParams={{[QUERY_PARAMS.ID_PRODUCT_GROUP]: categoryId}}
                        >
                            <Trans>Produkty</Trans>
                        </NavigateButton>
                    </div>
                    <div style={{width: '20rem'}}/>
                </div>
                <h2 className="mb-3 text-center">
                    <Trans>Úprava produktovej kategórie</Trans>
                </h2>
                <div>
                    <CategoryForm
                        submitLabel={<Trans>Upraviť</Trans>}
                        fetchFunc={createFetchCategoryEdit()}
                        onFinishSuccessful={(newCategoryId) => {
                            reload([GLOBAL_DATA.CATEGORIES, GLOBAL_DATA.CATEGORY_TREE, GLOBAL_DATA.PRODUCTS]);
                            navigateToParametrized(location, ROUTES.HOME, {[QUERY_PARAMS.ID_PRODUCT_GROUP]: newCategoryId});
                        }}
                        categoryId={categoryId}
                        isEdit={true}
                    />
                </div>
            </div>
        </React.Fragment>
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(locationHOC(CategoryEditPage));